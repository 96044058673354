<template>
  <div class="box">
    <van-image
      width="120"
      height="118"
      class="logo"
      :src="require('../../assets/images/logo.png')"
    />
    <div class="name">智能箱安装进度管理</div>
    <van-cell-group inset>
      <van-form>
        <van-field
          v-model="form.number"
          name="箱编号"
          label="箱编号"
          placeholder="请输入"
          input-align="right"
        />
      </van-form>
    </van-cell-group>
    <div class="btn-box">
      <van-button block type="info" @click="onNext"
        >下一步</van-button
      >
    </div>
  </div>
</template>

<script>
import { Toast } from "vant";
import { getProcessSelect } from "@/api/box";

export default {
  data() {
    return {
      form: {
        number: ""
      }
    };
  },
  methods: {
    // 提交
    async onNext() {
      const { number } = this.form;
      if (!number) {
        Toast("请输入箱编号");
        return;
      }
      if (number.length > 32) {
        Toast("箱编号长度应小于32位")
        return 
      }

      if (this.form.number.length > 32) {
        Toast("箱编号长度应小于32位")
        this.$set(this.form, 'number', '')
        return 
      }
      const res = await getProcessSelect(
        this.$route.query.id,
        this.form.number
      );
      if (res.data.length) {
        this.$router.push({ path: '/box2', query: { id: this.$route.query.id, number: this.form.number, can: res.data.join() }})
      } else {
        Toast("该编号箱子已完成安装，请确认箱编号重新输入");
      }

    }
  },
};
</script>

<style lang="less" scoped>
.box {
  padding-top: 24px;
  text-align: center;
  background: #fff;
  min-height: 100vh;
  position: relative;
  .logo {
    margin-top: 48px;
    margin-bottom: 24px;
  }
  .name {
    font-size: 24px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #323233;
    line-height: 33px;
    margin-bottom: 40px;
  }
  ::v-deep .van-cell {
    padding: 16px;
  }
  ::v-deep .van-field__label {
    color: #323233;
    font-size: 17px;
  }
  ::v-deep .van-field__value {
    font-size: 17px;
  }
  ::v-deep .van-field__control {
    color: #969799;
  }
}
.btn-box {
  position: absolute;
  bottom: 74px;
  width: 343px;
  left: 16px;
}
</style>